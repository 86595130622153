import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';

import HeroText from '../components/hero-text';
import { renderContent, setColorMode } from '../utilities/functions';
import { Container } from '../elements/layouts';
import Footer from '../components/footer';

const About = ({ data: { about }, location }) => {
  const { headlineNode, content } = about;
  const { htmlAst } = headlineNode.childMarkdownRemark;

  useEffect(() => {
    setColorMode(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <HeroText headline={htmlAst} />
      <Container>
        <section css={css``}>{renderContent(content)}</section>
      </Container>
      <Footer pathname={location.pathname} />
    </>
  );
};

export default About;

export const query = graphql`
  query AboutQuery {
    about: datoCmsAbout {
      id
      title
      headlineNode {
        childMarkdownRemark {
          htmlAst
        }
      }
      content {
        ... on DatoCmsSingleImage {
          id
          alignment
          image {
            alt
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
        }
        ... on DatoCmsImageGrid {
          id
          images {
            alt
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
        }
        ... on DatoCmsTextBlock {
          id
          heading
          paragraph
        }
        ... on DatoCmsTextContent {
          id
          alignment
          width
          height
          textNode {
            childMarkdownRemark {
              htmlAst
            }
          }
        }
      }
    }
  }
`;
